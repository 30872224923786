import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwt verify`}</strong>{` -- verify a signed JWT data structure and return the payload`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwt verify
[--aud=<audience>] [--iss=<issuer>] [--alg=<algorithm>]
[--key=<file>] [--jwks=<jwks>] [--kid=<kid>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto jwt verify`}</strong>{` reads a JWT data structure from STDIN; checks that
the audience, issuer, and algorithm are in agreement with expectations;
verifies the digital signature or message authentication code as appropriate;
and outputs the decoded payload of the JWT on STDOUT. If verification fails a
non-zero failure code is returned. If verification succeeds the command
returns 0.`}</p>
    <p>{`For a JWT to be verified successfully:`}</p>
    <ul>
      <li parentName="ul">{`The JWT must be well formed (no errors during deserialization)`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`algorithm`}</inlineCode>{` must match the `}<strong parentName="li">{`"alg"`}</strong>{` member in the JWT header`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`issuer`}</inlineCode>{` and `}<inlineCode parentName="li">{`audience`}</inlineCode>{` must match the `}<strong parentName="li">{`"iss"`}</strong>{` and `}<strong parentName="li">{`"aud"`}</strong>{` claims in the JWT,
respectively`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`kid`}</inlineCode>{` must match the `}<strong parentName="li">{`"kid"`}</strong>{` member in the JWT header (if both are
present) and must match the `}<strong parentName="li">{`"kid"`}</strong>{` in the JWK or the `}<strong parentName="li">{`"kid"`}</strong>{` of one of the
JWKs in JWKS`}</li>
      <li parentName="ul">{`The JWT signature must be successfully verified`}</li>
      <li parentName="ul">{`The JWT must not be expired`}</li>
    </ul>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto jwt`}</strong>{`.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--iss`}</strong>{`=`}<inlineCode parentName="p">{`issuer`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`issuer`}</inlineCode>{`
The issuer of this JWT. The `}<inlineCode parentName="p">{`issuer`}</inlineCode>{` must match the value of the `}<strong parentName="p">{`"iss"`}</strong>{` claim in
the JWT. `}<inlineCode parentName="p">{`issuer`}</inlineCode>{` is a case-sensitive string. Required unless disabled with the `}<strong parentName="p">{`--subtle`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--aud`}</strong>{`=`}<inlineCode parentName="p">{`audience`}</inlineCode>{`, `}<strong parentName="p">{`--audience`}</strong>{`=`}<inlineCode parentName="p">{`audience`}</inlineCode>{`
The identity of the principal running this command. The `}<inlineCode parentName="p">{`audience`}</inlineCode>{` specified
must match one of the values in the `}<strong parentName="p">{`"aud"`}</strong>{` claim, indicating the intended
recipient(s) of the JWT. `}<inlineCode parentName="p">{`audience`}</inlineCode>{` is a case-sensitive string. Required unless disabled with the
`}<strong parentName="p">{`--subtle`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`, `}<strong parentName="p">{`--algorithm`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The signature or MAC `}<inlineCode parentName="p">{`algorithm`}</inlineCode>{` to use. Algorithms are case-sensitive strings
defined in RFC7518. If the key used do verify the JWT is not a JWK, or if it
is a JWK but does not have an `}<strong parentName="p">{`"alg"`}</strong>{` member indicating its the intended
algorithm for use with the key, then the `}<strong parentName="p">{`--alg`}</strong>{` flag is required to prevent
algorithm downgrade attacks. To disable this protection you can pass the
`}<strong parentName="p">{`--insecure`}</strong>{` flag and omit the `}<strong parentName="p">{`--alg`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the key to use to verify the JWT.
The contents of the file can be a public or private JWK (or a JWK
encrypted as a JWE payload) or a public or private PEM (or a private key
encrypted using the modes described on RFC 1423 or with PBES2+PBKDF2 described
in RFC 2898).`}</p>
    <p><strong parentName="p">{`--jwks`}</strong>{`=`}<inlineCode parentName="p">{`jwks`}</inlineCode>{`
The JWK Set containing the key to use to verify the JWS. The `}<inlineCode parentName="p">{`jwks`}</inlineCode>{` argument
should be the name of a file. The file contents should be a JWK Set or a JWE
with a JWK Set payload. The JWS being verified should have a "kid" member that
matches the "kid" of one of the JWKs in the JWK Set. If the JWS does not have
a "kid" member the '--kid' flag can be used.`}</p>
    <p><strong parentName="p">{`--kid`}</strong>{`=`}<inlineCode parentName="p">{`kid`}</inlineCode>{`
The ID of the key used to sign the JWK, used to select a JWK from a JWK Set.
The KID argument is a case-sensitive string. If the input JWS has a "kid"
member its value must match `}<inlineCode parentName="p">{`kid`}</inlineCode>{` or verification will fail.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the key.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      